Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    themeActionPrimaryDefaultLight: tokens.palette500,
    themeActionPrimaryDefaultDark: tokens.palette300,
    themeActionPrimaryHoverLight: tokens.palette600,
    themeActionPrimaryHoverDark: tokens.palette200,
    themeActionPrimaryPressedLight: tokens.palette700,
    themeActionPrimaryPressedDark: tokens.palette100,
    themeActionPrimaryDisabledLight: tokens.paletteMuted300,
    themeActionPrimaryDisabledDark: tokens.paletteMuted500,
    themeActionPrimaryDisabledSubduedLight: tokens.paletteMuted100,
    themeActionPrimaryDisabledSubduedDark: tokens.paletteMuted700,
    themeActionSecondaryDefaultLight: "#CCD5DE",
    themeActionSecondaryDefaultDark: "#575F67",
    themeActionSecondaryHoverLight: "#B1BAC3",
    themeActionSecondaryHoverDark: "#6e777f",
    themeActionSecondaryPressedLight: "#89929A",
    themeActionSecondaryPressedDark: "#89929A",
    themeActionSecondaryDisabledLight: "#E9EBEF",
    themeActionSecondaryDisabledDark: "#222B32",
    themeActionNeutralDefaultLight: "rgba(0, 0, 0, 0)",
    themeActionNeutralDefaultDark: "rgba(0, 0, 0, 0)",
    themeActionNeutralSelectedLight: "#E9EBEF",
    themeActionNeutralSelectedDark: "#222B32",
    themeActionNeutralHoverLight: "#CCD5DE",
    themeActionNeutralHoverDark: "#575F67",
    themeActionNeutralPressedLight: "#B1BAC3",
    themeActionNeutralPressedDark: "#6e777f",
    themeActionNeutralDisabledLight: "#F7F6F8",
    themeActionNeutralDisabledDark: "#0E171D",
    themeInkDefaultLight: "#02080E",
    themeInkDefaultDark: "#FFFFFF",
    themeInkSubduedLight: "#575F67",
    themeInkSubduedDark: "#CCD5DE",
    themeInkInverseLight: "#FFFFFF",
    themeInkInverseDark: "#02080E",
    themeInkDisabledLight: "#B1BAC3",
    themeInkDisabledDark: "#6e777f",
    themeBorderDefaultLight: "#B1BAC3",
    themeBorderDefaultDark: "#6e777f",
    themeBorderSubduedLight: "#E9EBEF",
    themeBorderSubduedDark: "#222B32",
    themeBorderSurfaceLight: "#CCD5DE",
    themeBorderSurfaceDark: "#575F67",
    themeBorderFormfieldLight: "#6e777f",
    themeBorderFormfieldDark: "#B1BAC3",
    themeBorderFocusLight: tokens.palette400,
    themeBorderFocusDark: tokens.palette400,
    themeBorderDisabledLight: "#CCD5DE",
    themeBorderDisabledDark: "#575F67",
    themeBorderStrongLight: "#02080E",
    themeBorderStrongDark: "#FFFFFF",
    themeBorderInverseLight: "#FFFFFF",
    themeBorderInverseDark: "#02080E",
    themeBorderHiddenLight: "rgba(0, 0, 0, 0)",
    themeBorderHiddenDark: "#6e777f",
    themeBorderPaletteLight: tokens.palette300,
    themeBorderPaletteDark: tokens.palette400,
    themeBorderHighlightLight: "#89929A",
    themeBorderHighlightDark: "#6e777f",
    themeDecorativePaletteDefaultLight: tokens.palette400,
    themeDecorativePaletteDefaultDark: tokens.palette400,
    themeDecorativePaletteStrongLight: tokens.palette500,
    themeDecorativePaletteStrongDark: tokens.palette300,
    themeDecorativePaletteHighlightLight: tokens.palette300,
    themeDecorativePaletteHighlightDark: tokens.palette500,
    themeDecorativePaletteVibrantLight: tokens.palette200,
    themeDecorativePaletteVibrantDark: tokens.palette400,
    themeDecorativePaletteSubduedLight: tokens.palette100,
    themeDecorativePaletteSubduedDark: tokens.palette700,
    themeDecorativeNeutralDefaultLight: "#89929A",
    themeDecorativeNeutralDefaultDark: "#89929A",
    themeDecorativeNeutralStrongLight: "#6e777f",
    themeDecorativeNeutralStrongDark: "#B1BAC3",
    themeDecorativeNeutralHighlightLight: "#B1BAC3",
    themeDecorativeNeutralHighlightDark: "#6e777f",
    themeDecorativeNeutralSubduedLight: "#E9EBEF",
    themeDecorativeNeutralSubduedDark: "#222B32",
    themeSupportInkErrorLight: "#c8051e",
    themeSupportInkErrorDark: "#ff4c4a",
    themeSupportInkWarningLight: "#9f6100",
    themeSupportInkWarningDark: "#fd9e00",
    themeSupportInkSuccessLight: "#2f6e00",
    themeSupportInkSuccessDark: "#63a63a",
    themeSupportBorderErrorLight: "#c8051e",
    themeSupportBorderErrorDark: "#ff4c4a",
    themeSupportBorderWarningLight: "#c97c02",
    themeSupportBorderWarningDark: "#fd9e00",
    themeSupportBorderSuccessLight: "#529329",
    themeSupportBorderSuccessDark: "#63a63a",
    themeSupportFillErrorLight: "#ffedeb",
    themeSupportFillErrorDark: "#3d0003",
    themeSupportFillWarningLight: "#fff3ea",
    themeSupportFillWarningDark: "#392000",
    themeSupportFillSuccessLight: "#ebf4de",
    themeSupportFillSuccessDark: "#0e2a00",
    themeSurfaceDefaultLight: "#FFFFFF",
    themeSurfaceDefaultDark: "#0E171D",
    themeSurfaceDisabledLight: "#E9EBEF",
    themeSurfaceDisabledDark: "#222B32",
    themeSurfacePaletteLight: tokens.palette50,
    themeSurfacePaletteDark: tokens.palette800,
    themeOverlayDefaultLight: "#02080E",
    themeOverlayDefaultDark: "#6e777f",
    themePageDefaultLight: "#FFFFFF",
    themePageDefaultDark: "#02080E",
    themePageHighlightLight: "#F7F6F8",
    themePageHighlightDark: "#0E171D",
    themePageSubduedLight: "#F7F6F8",
    themePageSubduedDark: "#02080E",
    themePagePaletteLight: tokens.palette50,
    themePagePaletteDark: tokens.palette700,
    themeIllustrationStrokeDefaultLight: "#02080E",
    themeIllustrationStrokeDefaultDark: "#F7F6F8",
    themeIllustrationPaletteDefaultLight: tokens.palette300,
    themeIllustrationPaletteDefaultDark: tokens.palette400,
    themeIllustrationPaletteSubduedLight: tokens.palette100,
    themeIllustrationPaletteSubduedDark: tokens.palette700,
    _themeColorScaleCategoricalLight: ["#36C2B4","#EB64D7","#FF8F00","#8169F8","#FCC201","#FF5A94","#708090"],
    _themeColorScaleCategoricalDark: ["#36C2B4","#EB64D7","#FF8F00","#8169F8","#FCC201","#FF5A94","#708090"],
    _themeColorScaleComparisonLight: ["#348118","#DE1B1B"],
    _themeColorScaleComparisonDark: ["#47B120","#EE5A46"],
    _themeColorScaleVioletLight: ["#DDC8FF","#C9A8FF","#A588FF","#8169F8","#614FD9","#3E36BB"],
    _themeColorScaleVioletDark: ["#3E36BB","#614FD9","#8169F8","#A588FF","#C9A8FF","#DDC8FF"],
    _themeColorScalePurpleLight: ["#E9C6E4","#ECA8E0","#EC88DC","#EB64D7","#C54FB4","#8C297D"],
    _themeColorScalePurpleDark: ["#8C297D","#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#E9C6E4"],
    _themeColorScalePinkLight: ["#FFDBE5","#FFB5CA","#FF8CAF","#FF5A94","#DC3C71","#BA1750"],
    _themeColorScalePinkDark: ["#BA1750","#DC3C71","#FF5A94","#FF8CAF","#FFB5CA","#FFDBE5"],
    _themeColorScaleTangerineLight: ["#FFE5CB","#FFCB96","#FFAE5D","#FF8F00","#D4780C","#A45C0A"],
    _themeColorScaleTangerineDark: ["#A45C0A","#D4780C","#FF8F00","#FFAE5D","#FFCB96","#FFE5CB"],
    _themeColorScaleGoldLight: ["#FFF4D9","#FFE8B1","#FFDB86","#FCC201","#DAA500","#BF8E00"],
    _themeColorScaleGoldDark: ["#BF8E00","#DAA500","#FCC201","#FFDB86","#FFE8B1","#FFF4D9"],
    _themeColorScaleTurquoiseLight: ["#C3E9E7","#78D9CF","#36C2B4","#2C9F93","#227D74","#1B5F5B"],
    _themeColorScaleTurquoiseDark: ["#1B5F5B","#227D74","#2C9F93","#36C2B4","#78D9CF","#C3E9E7"],
    _themeColorScaleForestLight: ["#D6ECC9","#ABD994","#7EC55E","#47B120","#348118","#005C00"],
    _themeColorScaleForestDark: ["#005C00","#348118","#47B120","#7EC55E","#ABD994","#D6ECC9"],
    _themeColorScaleCrimsonLight: ["#FFD9CF","#FFB09F","#F98771","#EE5A46","#DE1B1B","#AF1F18"],
    _themeColorScaleCrimsonDark: ["#AF1F18","#DE1B1B","#EE5A46","#F98771","#FFB09F","#FFD9CF"],
    _themeColorScaleSlateLight: ["#DADEE2","#B6BDC6","#929EAB","#708090","#596571","#434C54"],
    _themeColorScaleSlateDark: ["#434C54","#596571","#708090","#929EAB","#B6BDC6","#DADEE2"],
    _themeColorScaleDivergingLight: ["#2C9F93","#36C2B4","#78D9CF","#C3E9E7","#FFF4D9","#FFE8B1","#FFDB86","#FCC201"],
    _themeColorScaleDivergingDark: ["#2C9F93","#36C2B4","#78D9CF","#C3E9E7","#FFF4D9","#FFE8B1","#FFDB86","#FCC201"],
    _themeColorScaleDivergingWithMiddleLight: ["#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#FFF4D9","#78D9CF","#36C2B4","#2C9F93","#227D74"],
    _themeColorScaleDivergingWithMiddleDark: ["#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#FFF4D9","#78D9CF","#36C2B4","#2C9F93","#227D74"]
  }
};