"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
// Clamps a value between a min and max
var clamp = function clamp(value, min, max) {
  // If any of the values can't be cast as Numbers, throw an Error
  if ([value, min, max].some(isNaN)) {
    throw Error('clamp only accepts arguments that can be cast as a Number (e.g. 2 or "2")');
  }
  return Math.min(Math.max(value, min), max);
};
var _default = clamp;
exports["default"] = _default;