"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _find = _interopRequireDefault(require("lodash/find"));
var _spacing = _interopRequireDefault(require("@amzn/meridian-tokens/base/spacing"));
var _token = require("../_utils/token");
var _propType = require("../_utils/prop-type");
// TODO: Split these into separate files so they're not all imported at once
// The options for spacing props are determined by the spacing tokens available
var spacingValues = Object.keys((0, _token.removeTokenPrefixes)(_spacing["default"], "spacing"));

// Spacing props must be space-separated strings of 1 to "maxCount" spacing
// identifiers (e.g. "500" or "400 500" or "400 none 500 none").
var spacing = function spacing(maxCount) {
  return (0, _propType.createCustom)(function (props, propName, componentName, location, propFullName) {
    var value = props[propName];
    var type = (0, _typeof2["default"])(value);
    var error = function error(msg) {
      return (0, _propType.getError)(componentName, location, propFullName, msg);
    };
    if (type === "undefined") {
      return null;
    } else if (type !== "string") {
      return error("string expected but ".concat(type, " received"));
    } else if (value.split(" ").length > maxCount) {
      return error("a maximum of ".concat(maxCount, " spacing value").concat(maxCount === 1 ? "" : "s", " may be provided"));
    } else if ((0, _find["default"])(value.split(" "), function (spacing) {
      return !(0, _includes["default"])(spacingValues, spacing);
    })) {
      return error("spacing values must be one of: ".concat(spacingValues.join(", ")));
    } else {
      return null;
    }
  });
};
var flexAlignmentValues = {
  row: {
    x: ["left", "center", "right", "justify"],
    y: ["top", "center", "bottom", "stretch"]
  },
  column: {
    x: ["left", "center", "right", "stretch"],
    y: ["top", "center", "bottom", "justify"]
  }
};

// Alignment props must be space-separated strings of 2 alignment identifiers
// (e.g. "top left" or "bottom right" or "center center").
var flexAlignment = function flexAlignment(flexDirection) {
  return (0, _propType.createCustom)(function (props, propName, componentName, location, propFullName) {
    var value = props[propName];
    var type = (0, _typeof2["default"])(value);
    var error = function error(msg) {
      return (0, _propType.getError)(componentName, location, propFullName, msg);
    };
    if (type === "undefined") {
      return null;
    } else if (type !== "string") {
      return error("string expected but ".concat(type, " received"));
    } else if (value.split(" ").length !== 2) {
      return error("2 values separated by a space must be provided");
    } else {
      var _value$split = value.split(" "),
        _value$split2 = (0, _slicedToArray2["default"])(_value$split, 2),
        y = _value$split2[0],
        x = _value$split2[1];
      var valuesX = flexAlignmentValues[flexDirection].x;
      var valuesY = flexAlignmentValues[flexDirection].y;
      if (!(0, _includes["default"])(valuesY, y)) {
        return error("the 1st value must be one of: ".concat(valuesY.join(", ")));
      } else if (!(0, _includes["default"])(valuesX, x)) {
        return error("the 2nd value must be one of: ".concat(valuesX.join(", ")));
      } else {
        return null;
      }
    }
  });
};
var alignmentValues = {
  x: ["left", "center", "right", "start", "end"],
  y: ["top", "center", "bottom"]
};

// Create a custom validator for alignment props (values like "top left")
var alignment = (0, _propType.createCustom)(function (props, propName, componentName, location, propFullName) {
  var value = props[propName];
  var type = (0, _typeof2["default"])(value);
  var error = function error(msg) {
    return (0, _propType.getError)(componentName, location, propFullName, msg);
  };
  if (type === "undefined") {
    return null;
  } else if (type !== "string") {
    return error("string expected but ".concat(type, " received"));
  } else if (value.split(" ").length !== 2) {
    return error("2 values separated by a space must be provided");
  } else {
    var _value$split3 = value.split(" "),
      _value$split4 = (0, _slicedToArray2["default"])(_value$split3, 2),
      y = _value$split4[0],
      x = _value$split4[1];
    var valuesX = alignmentValues.x;
    var valuesY = alignmentValues.y;
    if (!(0, _includes["default"])(valuesY, y)) {
      return error("the 1st value must be one of: ".concat(valuesY.join(", ")));
    } else if (!(0, _includes["default"])(valuesX, x)) {
      return error("the 2nd value must be one of: ".concat(valuesX.join(", ")));
    } else {
      return null;
    }
  }
});
var _default = {
  spacing: spacing,
  flexAlignment: flexAlignment,
  alignment: alignment
};
exports["default"] = _default;