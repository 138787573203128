Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    textD300FontSize: 76,
    textD300FontWeight: 200,
    textD300LineHeight: "96px",
    textD200FontSize: 54,
    textD200FontWeight: 200,
    textD200LineHeight: "64px",
    textD100FontSize: 48,
    textD100FontWeight: 200,
    textD100LineHeight: "60px",
    textD50FontSize: 36,
    textD50FontWeight: 200,
    textD50LineHeight: "48px",
    textH600FontSize: 36,
    textH600FontWeight: 600,
    textH600LineHeight: "48px",
    textH500FontSize: 28,
    textH500FontWeight: 600,
    textH500LineHeight: "36px",
    textH400FontSize: 24,
    textH400FontWeight: 600,
    textH400LineHeight: "32px",
    textH300FontSize: 20,
    textH300FontWeight: 600,
    textH300LineHeight: "24px",
    textH200FontSize: 18,
    textH200FontWeight: 600,
    textH200LineHeight: "24px",
    textH100FontSize: 16,
    textH100FontWeight: 600,
    textH100LineHeight: "20px",
    textH50FontSize: 14,
    textH50FontWeight: 600,
    textH50LineHeight: "18px",
    textB500FontSize: 20,
    textB500FontWeight: 400,
    textB500LineHeight: "28px",
    textB400FontSize: 18,
    textB400FontWeight: 400,
    textB400LineHeight: "28px",
    textB300FontSize: 16,
    textB300FontWeight: 400,
    textB300LineHeight: "24px",
    textB200FontSize: 14,
    textB200FontWeight: 400,
    textB200LineHeight: "20px",
    textB100FontSize: 12,
    textB100FontWeight: 400,
    textB100LineHeight: "16px",
    textFontSizeD300: 76,
    textFontSizeD200: 54,
    textFontSizeD100: 48,
    textFontSizeD50: 36,
    textFontSizeH600: 36,
    textFontSizeH500: 28,
    textFontSizeH400: 24,
    textFontSizeH300: 20,
    textFontSizeH200: 18,
    textFontSizeH100: 16,
    textFontSizeH50: 14,
    textFontSizeB500: 20,
    textFontSizeB400: 18,
    textFontSizeB300: 16,
    textFontSizeB200: 14,
    textFontSizeB100: 12,
    textFontWeightD300: 200,
    textFontWeightD200: 200,
    textFontWeightD100: 200,
    textFontWeightD50: 200,
    textFontWeightH600: 600,
    textFontWeightH500: 600,
    textFontWeightH400: 600,
    textFontWeightH300: 600,
    textFontWeightH200: 600,
    textFontWeightH100: 600,
    textFontWeightH50: 600,
    textFontWeightB500: 400,
    textFontWeightB400: 400,
    textFontWeightB300: 400,
    textFontWeightB200: 400,
    textFontWeightB100: 400,
    textLineHeightD300: "96px",
    textLineHeightD200: "64px",
    textLineHeightD100: "60px",
    textLineHeightD50: "48px",
    textLineHeightH600: "48px",
    textLineHeightH500: "36px",
    textLineHeightH400: "32px",
    textLineHeightH300: "24px",
    textLineHeightH200: "24px",
    textLineHeightH100: "20px",
    textLineHeightH50: "18px",
    textLineHeightB500: "28px",
    textLineHeightB400: "28px",
    textLineHeightB300: "24px",
    textLineHeightB200: "20px",
    textLineHeightB100: "16px",
    textFontFamilyAmazonEmber: "\"Amazon Ember\", \"Amazon Ember Arabic\", Arial, sans-serif",
    textFontFamilyAmazonEmberArabic: "\"Amazon Ember Arabic\", \"Amazon Ember\", Arial, sans-serif",
    textFontFamilyAmazonEmberMonospace: "\"Amazon Ember Monospace\", \"Courier New\", Courier, monospace",
    textFontFamilyBookerly: "Bookerly, Georgia, serif",
    textFontFamilyBookerlyDisplay: "\"Bookerly Display\", Georgia, serif",
    textDefaultFontSize: 16,
    textDefaultFontWeight: 400,
    textDefaultLineHeight: "24px",
    textDefaultFontFamily: "\"Amazon Ember\", \"Amazon Ember Arabic\", Arial, sans-serif",
    textColorPrimary: tokens.themeInkDefault,
    textColorSecondary: tokens.themeInkSubdued,
    textColorError: tokens.themeSupportInkError,
    textColorInverted: tokens.themeInkInverse,
    textListSpacingHorizontal: 20,
    textListSpacingVertical: 8
  }
};