"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
/** Returns a string value for a border radius style.
 *
 * eg: `0px 4px 4px 0px`
 *
 * @returns {string}
 */
var getBorderRadius = function getBorderRadius(borderRadius) {
  var sides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var topLeft = sides.includes("topLeft") ? borderRadius : 0;
  var topRight = sides.includes("topRight") ? borderRadius : 0;
  var bottomLeft = sides.includes("bottomLeft") ? borderRadius : 0;
  var bottomRight = sides.includes("bottomRight") ? borderRadius : 0;
  return "".concat(topLeft, "px ").concat(topRight, "px ").concat(bottomRight, "px ").concat(bottomLeft, "px");
};
var _default = getBorderRadius;
exports["default"] = _default;