"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var hexToRGB = function hexToRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
};
var translucent = function translucent(hex) {
  var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var _hexToRGB = hexToRGB(hex),
    r = _hexToRGB.r,
    g = _hexToRGB.g,
    b = _hexToRGB.b;
  var a = opacity / 100;
  return "rgba(".concat(r, ",").concat(g, ",").concat(b, ",").concat(a, ")");
};
var _default = translucent;
exports["default"] = _default;