"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequiredError = exports.getError = exports.createCustom = void 0;
/** Create an Error object with a human-readable message. */
var getError = function getError(componentName, location, propFullName, msg) {
  return new Error("Invalid ".concat(location, " `").concat(propFullName, "` supplied to `").concat(componentName, "`: ").concat(msg));
};

/** Create a required Error object with a human-readable message that follows
 the same conventions as Facebooks's prop-types library */
exports.getError = getError;
var getRequiredError = function getRequiredError(componentName, location, propFullName, actualValue) {
  return new Error("The ".concat(location, " `").concat(propFullName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(actualValue, "`."));
};

/** Create a custom prop type with the .isRequired convention built-in */
exports.getRequiredError = getRequiredError;
var createCustom = function createCustom(customValidator) {
  var createValidator = function createValidator(isRequired) {
    return function (props, propName, componentName, location, propFullName) {
      componentName = componentName || "<<anonymous>>";
      propFullName = propFullName || propName;
      if (isRequired && props[propName] === null) {
        return getRequiredError(componentName, location, propFullName, "null");
      } else if (isRequired && props[propName] === undefined) {
        return getRequiredError(componentName, location, propFullName, "undefined");
      } else {
        return customValidator(props, propName, componentName, location, propFullName);
      }
    };
  };
  var requirableValidator = createValidator(false);
  requirableValidator.isRequired = createValidator(true);
  return requirableValidator;
};
exports.createCustom = createCustom;