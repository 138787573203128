"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _useMatchMedia = _interopRequireDefault(require("../_use-match-media"));
/**
 * @param {('light'|'dark')} [fallback] - The value to fallback to on initial
 *   mount. Useful for server-side rendering.
 * @returns {('light'|'dark')} - A string describing the browser's preferred
 * color scheme. See [mdn web docs](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme) for
 * more information.
 */
var usePrefersColorScheme = function usePrefersColorScheme(fallback) {
  var prefersDarkMode = (0, _useMatchMedia["default"])({
    feature: "prefers-color-scheme",
    breakpoint: "dark",
    fallback: fallback
  });
  return prefersDarkMode ? "dark" : "light";
};
var _default = usePrefersColorScheme;
exports["default"] = _default;