"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
var _useTheme = _interopRequireDefault(require("./use-theme"));
/**
 * IMPORTANT: For backwards-compatibility only! Use the "useTheme" hook instead.
 */
function Style(_ref) {
  var tokens = _ref.tokens,
    map = _ref.map,
    children = _ref.children;
  return children((0, _useTheme["default"])(tokens, "", map || true));
}
Style.propTypes = {
  children: _propTypes["default"].func.isRequired,
  map: _propTypes["default"].func,
  tokens: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].object])
};
var _default = Style;
exports["default"] = _default;