"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propTypeShims = _interopRequireDefault(require("./prop-type-shims"));
var _propTypes = _interopRequireDefault(require("./prop-types"));
var _default = process.env.NODE_ENV === "production" ? _propTypeShims["default"] : _propTypes["default"];
exports["default"] = _default;