Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports = {
  spacing100: 2,
  spacing200: 4,
  spacing300: 8,
  spacing350: 12,
  spacing400: 16,
  spacing450: 24,
  spacing500: 32,
  spacing600: 64,
  spacingNone: 0
};