"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _css = require("@emotion/css");
var _functional = require("./functional");
var cxMemoizedCurried = (0, _functional.memoizeCurry)(_css.cx, 1);

/**
 * A memoized version of cx. Only supports two classes.
 */
var cxMemoized = function cxMemoized(a, b) {
  return cxMemoizedCurried(a)(b);
};
var _default = cxMemoized;
exports["default"] = _default;