import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createRootReducer from '../reducers';

const history = createBrowserHistory();
const { routerMiddleware, routerReducer, createReduxHistory } = createReduxHistoryContext({
  history,
});
const routeMiddleware = routerMiddleware;

// Middleware you want to use in production:
const enhancer = applyMiddleware(routeMiddleware);

export function configureStore(initialState) {
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(createRootReducer(routerReducer), initialState, enhancer);
  const reduxHistory = createReduxHistory(store);
  return { store, history: reduxHistory };
}
