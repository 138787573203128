Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    loaderIndicatorBackgroundColor: tokens.themeDecorativePaletteDefault,
    loaderIndicatorMotionIndeterminateDuration: "700ms",
    loaderIndicatorMotionIndeterminateFunction: "linear",
    loaderIndicatorMotionDeterminateDuration: "150ms",
    loaderIndicatorMotionDeterminateFunction: "linear",
    loaderTrackBackgroundColor: tokens.themeDecorativeNeutralSubdued,
    loaderTrackLinearLargeHeight: 8,
    loaderTrackLinearSmallHeight: 4,
    loaderTrackCircularLargeSize: 64,
    loaderTrackCircularLargeStrokeWidth: 4,
    loaderTrackCircularMediumSize: 32,
    loaderTrackCircularMediumStrokeWidth: 4,
    loaderTrackCircularSmallSize: 16,
    loaderTrackCircularSmallStrokeWidth: 2,
    loaderPercentageSpacing: 8,
    loaderPercentageForegroundColor: tokens.themeInkDefault,
    loaderPercentageLinearSmallFontSize: 14,
    loaderPercentageLinearSmallFontWeight: 400,
    loaderPercentageLinearSmallLineHeight: "20px",
    loaderPercentageLinearLargeFontSize: 14,
    loaderPercentageLinearLargeFontWeight: 400,
    loaderPercentageLinearLargeLineHeight: "20px",
    loaderPercentageCircularSmallFontSize: 12,
    loaderPercentageCircularSmallFontWeight: 400,
    loaderPercentageCircularSmallLineHeight: "16px",
    loaderPercentageCircularMediumFontSize: 12,
    loaderPercentageCircularMediumFontWeight: 400,
    loaderPercentageCircularMediumLineHeight: "16px",
    loaderPercentageCircularLargeFontSize: 14,
    loaderPercentageCircularLargeFontWeight: 400,
    loaderPercentageCircularLargeLineHeight: "20px",
    loaderBorderWidth: 1,
    loaderBorderRadius: 4,
    loaderBorderColor: tokens.themeDecorativePaletteDefault,
    loaderFontSizeLinear: 14,
    loaderFontSizeSmall: 12,
    loaderFontSizeMedium: 12,
    loaderFontSizeLarge: 14,
    loaderLineHeightLinear: "20px",
    loaderLineHeightSmall: "16px",
    loaderLineHeightMedium: "16px",
    loaderLineHeightLarge: "20px"
  }
};