"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Style", {
  enumerable: true,
  get: function get() {
    return _style["default"];
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _useTheme["default"];
  }
});
require("./global-styles");
var _theme = _interopRequireDefault(require("./theme"));
var _useTheme = _interopRequireDefault(require("./use-theme"));
var _style = _interopRequireDefault(require("./style"));
var _default = _theme["default"];
exports["default"] = _default;