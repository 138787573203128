"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
var _token = require("./../_utils/token");
var _utils = require("./utils");
var useTheme = function useTheme(tokens) {
  var prefixKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var _legacyHelperForBackwardsCompatibilityDoNotUse = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var themeContext = (0, _react.useContext)(_utils.ThemeContext);
  var currentTokens = (0, _utils.applyTheme)(themeContext.tokens)(tokens);
  var mode = themeContext.mode;
  if (typeof _legacyHelperForBackwardsCompatibilityDoNotUse === "function") {
    return _legacyHelperForBackwardsCompatibilityDoNotUse(currentTokens);
  } else if (_legacyHelperForBackwardsCompatibilityDoNotUse) {
    return currentTokens;
  } else {
    var helper = (0, _token.getTokenHelper)(prefixKey)(currentTokens);
    helper.mode = mode;
    return helper;
  }
};
var _default = useTheme;
exports["default"] = _default;