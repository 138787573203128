"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.themedStyles = exports.styles = exports.cardStyles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _css = require("@emotion/css");
var _functional = require("../_utils/functional");
var _borderRadius = _interopRequireDefault(require("../_utils/border-radius"));
var _getInlinePadding = _interopRequireDefault(require("../_utils/get-inline-padding"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var cardStyles = (0, _functional.memoize)(function (c) {
  var borderRadius = c("borderRadius");
  var imageBorderRadius = borderRadius - c("borderWidth");
  return (0, _css.css)(_objectSpread(_objectSpread({}, c.typographyCss("textDefault")), {}, {
    color: c("textDefaultForegroundColor"),
    border: c.borderCss("border"),
    borderRadius: borderRadius,
    boxShadow: c.boxShadowCss("dropShadowDefault"),
    position: "relative",
    "& > *[role=img]": {
      borderRadius: (0, _borderRadius["default"])(imageBorderRadius, ["topLeft", "topRight"])
    }
  }));
});
exports.cardStyles = cardStyles;
var styles = (0, _functional.memoizeByKeys)(function (_ref) {
  var backgroundColor = _ref.backgroundColor,
    borderColor = _ref.borderColor,
    borderWidth = _ref.borderWidth,
    borderRadius = _ref.borderRadius,
    width = _ref.width,
    height = _ref.height,
    minWidth = _ref.minWidth,
    minHeight = _ref.minHeight,
    maxWidth = _ref.maxWidth,
    maxHeight = _ref.maxHeight,
    overflowX = _ref.overflowX,
    overflowY = _ref.overflowY,
    spacingInset = _ref.spacingInset;
  return (0, _css.css)(_objectSpread(_objectSpread({
    boxSizing: "border-box",
    // If we have a border radius defined then clip content so that it doesn't
    // bleed out the corners
    overflow: borderRadius ? "hidden" : undefined,
    overflowX: overflowX ? overflowX : undefined,
    overflowY: overflowY ? overflowY : undefined
  }, (0, _getInlinePadding["default"])(spacingInset)), {}, {
    borderStyle: borderWidth && borderColor ? "solid" : undefined,
    borderWidth: borderWidth,
    borderColor: borderColor,
    borderRadius: borderRadius,
    backgroundColor: backgroundColor,
    width: width,
    height: height,
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    maxHeight: maxHeight
  }));
}, ["backgroundColor", "borderColor", "borderWidth", "borderRadius", "width", "height", "minWidth", "minHeight", "maxWidth", "maxHeight", "overflowX", "overflowY", "spacingInset"]);
exports.styles = styles;
var themedStyles = (0, _functional.memoize)(function (t) {
  return (0, _functional.memoizeByKeys)(function (_ref2) {
    var type = _ref2.type,
      backgroundColor = _ref2.backgroundColor,
      width = _ref2.width,
      height = _ref2.height,
      minWidth = _ref2.minWidth,
      minHeight = _ref2.minHeight,
      maxWidth = _ref2.maxWidth,
      maxHeight = _ref2.maxHeight,
      overflowX = _ref2.overflowX,
      overflowY = _ref2.overflowY,
      spacingInset = _ref2.spacingInset;
    return styles({
      backgroundColor: backgroundColor ? t("backgroundColor", backgroundColor) || backgroundColor : t("backgroundColor", type),
      borderColor: t("borderColor", type),
      borderWidth: t("borderWidth"),
      borderRadius: t("borderRadius", type),
      width: width,
      height: height,
      minWidth: minWidth,
      minHeight: minHeight,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      overflowX: overflowX,
      overflowY: overflowY,
      spacingInset: spacingInset
    });
  }, ["type", "backgroundColor", "width", "height", "minWidth", "minHeight", "maxWidth", "maxHeight", "overflowX", "overflowY", "spacingInset"]);
});
exports.themedStyles = themedStyles;