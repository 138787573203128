"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.memoize = memoize;
exports.memoizeArray = memoizeArray;
exports.memoizeByKeys = memoizeByKeys;
exports.memoizeCurry = memoizeCurry;
/**
 * Author: shekwong@
 */

var END = new Object();

/**
 * Memoize a function with one argument.
 *
 * @example
 * const square = a => a * a
 * const memoizedSquare = memoize(square)
 * memoizedSquare(2) // 4
 */
function memoize(f) {
  var memo = new Map();
  return function (i) {
    if (memo.has(i)) {
      return memo.get(i);
    }
    var result = f(i);
    memo.set(i, result);
    return result;
  };
}

/**
 * Memoize a function with N args by currying it
 *
 * @example
 * const multiply = (a, b) => a * b
 * const memoizedMultiply = memoize(multiply, 1)
 * memoizedMultiply(2)(3) // 6
 */
function memoizeCurry(f, depth) {
  var a = f;
  if (depth == 0) {
    return memoize(a);
  }
  return memoize(function (x) {
    return memoizeCurry(a.bind(null, x), depth - 1);
  });
}

/**
 * A helper function used by memoizeByKeys.
 */
function memoizeByKeysCurry(f, resolver, depth) {
  var a = f;
  if (depth == 0) {
    return memoize(resolver);
  }
  return memoize(function (x) {
    return memoizeByKeysCurry(a.bind(null, x), resolver, depth - 1);
  });
}

/**
 * Memoize a function that accepts an object as its only argument. You must
 * specify the keys in the object to compare when determining whether to re-use
 * a previous calculation.
 *
 * NOTE: This can only support properties in the object that are simple (e.g.
 * strings, numbers, booleans, not nested objects or arrays).
 *
 * @example
 * const multiply = ({ a, b }) => a * b
 * cont memoizedMultiply = memoizeByKeys(multiply, ["a", "b"])
 * memoizedMultiply({ a: 2, b: 3 }) // 6
 */
function memoizeByKeys(f, keys) {
  var args = undefined;
  var g = memoizeByKeysCurry(f, function () {
    return f(args);
  }, keys.length - 1);
  return function (a) {
    if (!a) {
      var _c = g;
      keys.forEach(function () {
        _c = _c(undefined);
      });
      return _c;
    }
    args = a;
    var c = g;
    keys.forEach(function (k) {
      c = c(args[k]);
    });
    return c;
  };
}

/**
 * A helper function used by memoizeArray.
 */
function memoizeByVarArgsCurry(f) {
  var a = f;
  return memoize(function (x) {
    return x === END ? a() : memoizeByVarArgsCurry(a.bind(null, x));
  });
}

/**
 * Memoize a function that accepts an array as its only argument.
 *
 * @example
 * const multiply = numbers => numbers.reduce((result, num) => result * num)
 * cont memoizedMultiply = memoizeArray(multiply)
 * memoizedMultiply([2, 3, 4]) // 24
 */
function memoizeArray(f) {
  var memoized = memoizeByVarArgsCurry(function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return f(args);
  });
  return function (vals) {
    var g = memoized;
    vals.forEach(function (v) {
      g = g(v);
    });
    return g(END);
  };
}