"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _matchMedia = _interopRequireDefault(require("../responsive/match-media"));
// Returns a boolean describing if a MediaQueryList object matches or not.
// This hook also adds an event listener that re-renders a component when
// the state of matches changes.
var useMatchMedia = function useMatchMedia(_ref) {
  var feature = _ref.feature,
    condition = _ref.condition,
    fallback = _ref.fallback,
    breakpoint = _ref.breakpoint;
  var mediaQueryRef = (0, _react.useRef)((0, _matchMedia["default"])({
    feature: feature,
    condition: condition,
    fallback: fallback,
    breakpoint: breakpoint
  }));

  // Initialize `matches` with if the current media query matches
  var _useState = (0, _react.useState)(mediaQueryRef.current.matches),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    matches = _useState2[0],
    setMatches = _useState2[1];
  (0, _react.useEffect)(function () {
    var mediaQuery = mediaQueryRef.current;
    // Updating `matches` here will only cause a re-render
    // if the initialized value (based on fallback or
    // lack thereof) is different then the for real
    // value after component has mounted.
    setMatches(mediaQuery.matches);
    var listener = function listener() {
      return setMatches(mediaQuery.matches);
    };
    // Add listener on mount and remove on unmount
    mediaQuery.addListener(listener);
    return function () {
      return mediaQuery.removeListener(listener);
    };
  }, []);
  return matches;
};
var _default = useMatchMedia;
exports["default"] = _default;