Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = function(tokens) {
  return {
    boxBackgroundColorOutline: tokens.themePageDefault,
    boxBackgroundColorFill: tokens.themePageHighlight,
    boxBackgroundColorPrincipal: tokens.themePageDefault,
    boxBackgroundColorHighlight: tokens.themePageHighlight,
    boxBackgroundColorSubdued: tokens.themePageSubdued,
    boxBorderWidth: 1,
    boxBorderRadiusOutline: 4,
    boxBorderRadiusFill: 4,
    boxBorderColorOutline: tokens.themeBorderDefault,
    boxBorderColorFill: tokens.themeBorderSubdued
  }
};