Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports = {
  colorTransparent: "rgba(0, 0, 0, 0)",
  colorGray0: "#FFFFFF",
  colorGray50: "#F7F6F8",
  colorGray100: "#E9EBEF",
  colorGray200: "#CCD5DE",
  colorGray300: "#B1BAC3",
  colorGray400: "#89929A",
  colorGray500: "#6e777f",
  colorGray600: "#575F67",
  colorGray700: "#222B32",
  colorGray750: "#0E171D",
  colorGray800: "#02080E",
  colorBlue50: "#DAF0FF",
  colorBlue100: "#B6E3FF",
  colorBlue200: "#6DCDFF",
  colorBlue300: "#00A8E1",
  colorBlue400: "#0085B3",
  colorBlue500: "#00688D",
  colorBlue600: "#004F6C",
  colorBlue700: "#003C52",
  colorBlue800: "#002A3C",
  colorBlueMuted100: "#D0DFEA",
  colorBlueMuted200: "#AFC6D5",
  colorBlueMuted300: "#89A0AF",
  colorBlueMuted400: "#697F8D",
  colorBlueMuted500: "#4E6471",
  colorBlueMuted600: "#364C59",
  colorBlueMuted700: "#243A46",
  colorSuccess50: "#ebf4de",
  colorSuccess200: "#63a63a",
  colorSuccess500: "#529329",
  colorSuccess600: "#2f6e00",
  colorSuccess800: "#0e2a00",
  colorWarning50: "#fff3ea",
  colorWarning200: "#fd9e00",
  colorWarning500: "#c97c02",
  colorWarning600: "#9f6100",
  colorWarning800: "#392000",
  colorError50: "#ffedeb",
  colorError200: "#ff4c4a",
  colorError600: "#c8051e",
  colorError800: "#3d0003",
  colorPink100: "#FFDBE5",
  colorPink200: "#FFB5CA",
  colorPink300: "#FF8CAF",
  colorPink400: "#FF5A94",
  colorPink500: "#DC3C71",
  colorPink600: "#BA1750",
  colorPurple100: "#E9C6E4",
  colorPurple200: "#ECA8E0",
  colorPurple300: "#EC88DC",
  colorPurple400: "#EB64D7",
  colorPurple500: "#C54FB4",
  colorPurple600: "#8C297D",
  colorTurquoise100: "#C3E9E7",
  colorTurquoise200: "#78D9CF",
  colorTurquoise300: "#36C2B4",
  colorTurquoise400: "#2C9F93",
  colorTurquoise500: "#227D74",
  colorTurquoise600: "#1B5F5B",
  colorTangerine100: "#FFE5CB",
  colorTangerine200: "#FFCB96",
  colorTangerine300: "#FFAE5D",
  colorTangerine400: "#FF8F00",
  colorTangerine500: "#D4780C",
  colorTangerine600: "#A45C0A",
  colorViolet100: "#DDC8FF",
  colorViolet200: "#C9A8FF",
  colorViolet300: "#A588FF",
  colorViolet400: "#8169F8",
  colorViolet500: "#614FD9",
  colorViolet600: "#3E36BB",
  colorGold100: "#FFF4D9",
  colorGold200: "#FFE8B1",
  colorGold300: "#FFDB86",
  colorGold400: "#FCC201",
  colorGold500: "#DAA500",
  colorGold600: "#BF8E00",
  colorForest100: "#D6ECC9",
  colorForest200: "#ABD994",
  colorForest300: "#7EC55E",
  colorForest400: "#47B120",
  colorForest500: "#348118",
  colorForest600: "#005C00",
  colorCrimson100: "#FFD9CF",
  colorCrimson200: "#FFB09F",
  colorCrimson300: "#F98771",
  colorCrimson400: "#EE5A46",
  colorCrimson500: "#DE1B1B",
  colorCrimson600: "#AF1F18",
  colorSlate100: "#DADEE2",
  colorSlate200: "#B6BDC6",
  colorSlate300: "#929EAB",
  colorSlate400: "#708090",
  colorSlate500: "#596571",
  colorSlate600: "#434C54",
  colorRed100: "#F9D5E2",
  colorRed300: "#FF7494",
  colorRed400: "#CC0C39",
  colorRed500: "#AD0A30",
  colorYellow100: "#FEF2B8",
  colorYellow300: "#FCDE4D",
  colorYellow400: "#FAD000",
  colorYellow500: "#DCB700",
  colorGreen100: "#DCF3A0",
  colorGreen300: "#C3EB5E",
  colorGreen400: "#ADE422",
  colorGreen500: "#98C91E",
  colorOrange50: "#FFE4DE",
  colorOrange100: "#FFCFC3",
  colorOrange200: "#FFA58E",
  colorOrange300: "#FF6138",
  colorOrange400: "#D6441D",
  colorOrange500: "#AE2800",
  colorOrange600: "#871D00",
  colorOrange700: "#671400",
  colorOrange800: "#4B0C00",
  colorTeal50: "#D7F7FF",
  colorTeal100: "#B4EBF9",
  colorTeal200: "#87DBED",
  colorTeal300: "#61B5C8",
  colorTeal400: "#3690A1",
  colorTeal500: "#067586",
  colorTeal600: "#005765",
  colorTeal700: "#00434E",
  colorTeal800: "#003139"
};