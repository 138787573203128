"use strict";

var _css = require("@emotion/css");
/**
 * Global styles
 */

(0, _css.injectGlobal)({
  /**
   * Set font smoothing technique
   *
   * On macOS the default font smoothing technique (subpixel antialiasing) is
   * optimized for dark text on light backgrounds. Unfortunately when applied to
   * light text on dark backgrounds this results in extra heavy, fuzzy text. The
   * following two properties turn off subpixel antialiasing in webkit browsers
   * and Firefox on macOS when the display's pixel density is high enough to
   * support full-pixel aliasing without decreasing text legibility.
   */
  "@media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx)": {
    body: {
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale"
    }
  }
});