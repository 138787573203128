"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _parsers = require("../_prop-types/parsers");
/**
 * Returns RTL friendly css padding for an array of spacingInset values,
 * since `padding` shorthand does not properly swap left/right for RTL
 * layouts.
 *
 * The left/right padding must be set to paddingInlineStart and paddingInlineEnd
 *
 * @param spacingInset a string of shorthand spacingInset values
 */
var getInlinePadding = function getInlinePadding(spacingInset) {
  var _ref;
  var _parseSpacing = (0, _parsers.parseSpacing)(spacingInset),
    _parseSpacing2 = (0, _slicedToArray2["default"])(_parseSpacing, 4),
    first = _parseSpacing2[0],
    second = _parseSpacing2[1],
    third = _parseSpacing2[2],
    fourth = _parseSpacing2[3];
  var padding = {
    paddingTop: first,
    paddingInlineEnd: second !== null && second !== void 0 ? second : first,
    paddingBottom: third !== null && third !== void 0 ? third : first,
    paddingInlineStart: (_ref = fourth !== null && fourth !== void 0 ? fourth : second) !== null && _ref !== void 0 ? _ref : first
  };
  return padding;
};
var _default = getInlinePadding;
exports["default"] = _default;